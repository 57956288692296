import React, { useState } from "react";
import axios from 'axios';

const ApiTest = () => {
  const [inputText, setInputText] = useState("");
  const [jobAds, setJobAds] = useState([]);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("http://127.0.0.1:5000/api/calculate", { text: inputText })  // Ändere die URL hier
      .then((response) => {
        console.log("Received job ads:", response.data);
        setJobAds(response.data);
      })
      .catch((error) => {
        console.error("There was an error posting the data!", error);
      });
  };

  const handleSaveResults = (event) => {
    event.preventDefault();
    const dataToSave = {
      experience: "1",
      field: "IT, Informatik",
      fitAds: "",
      jobAdsMethod1Reason: "jk",
      jobAdsMethod1Response: "2",
      jobAdsMethod2Reason: "jkjhk",
      jobAdsMethod2Response: "2",
      jobAdsMethod3Reason: "jkhj",
      jobAdsMethod3Response: "2",
      language: "Deutsch",
      resumeText: "fgh",
      status: "Arbeitend",
      whyFit: ""
    };

    axios
      .post("http://127.0.0.1:5000/api/saveResults", dataToSave)  // Ändere die URL hier
      .then((response) => {
        console.log("Data saved:", response.data);
      })
      .catch((error) => {
        console.error("There was an error saving the data!", error);
      });
  };

  return (
    <div className="App">
      <h1>Calculate Results Test 2</h1>

      <form onSubmit={handleSubmit}>
        <input type="text" value={inputText} onChange={handleInputChange} />
        <button type="submit">Send</button>
      </form>

      {jobAds.length > 0 && (
        <div>
          <h2>Job Ads:</h2>
          <ul>
            {jobAds.method1.map((ad, index) => (
              <li key={index}>
                <h3>{ad.angebothtitel}</h3>
                <p>{ad.kurzbeschreibung}</p>
                <h4>{ad.einleitungstitel}</h4>
                <p>{ad.einleitungstext}</p>
                <h4>{ad.aufgabentitel}</h4>
                <p>{ad.aufgabentext}</p>
                <h4>{ad.angebotstitel}</h4>
                <p>{ad.angebotstext}</p>
              </li>
            ))}
          </ul>
        </div>
      )}

      <button onClick={handleSaveResults}>Save Results</button>
    </div>
  );
};

export default ApiTest;