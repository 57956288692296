const SurveySteps = [
  {
    question: "Dein Aktueller Status",
    name: "status",
    options: ["Student", "Arbeitend", "In Ausbildung", "Sonstiges"],
    button: {
      label: "Weiter",
      action: "next",
    },
  },
  {
    question: (
      <>
        Wie bewerten Sie Ihre bisherige Erfahrung mit den Filtermöglichkeiten zur Suche nach passenden Stellenanzeigen?
        <br /> <br />1 = Es war sehr schwierig, passende Stellenanzeigen zu finden. Es wurden zu wenig passende oder zu viele unpassende Stellenanzeigen angezeigt.
        <br /> <br />10 = Die Filtermöglichkeiten waren hervorragend und haben bei der Suche sehr gut geholfen. Es wurden nur relevante Stellenanzeigen angezeigt.
        <br />
      </>
    ),
    name: "experience",
    options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    button: {
      label: "Weiter",
      action: "next",
    },
  },
  {
    question: "Welchem der 8 Berufsfeldern gehören Sie zu?",
    name: "field",
    options: [
      "Ingenieurwesen und Technik",
      "IT, Informatik",
      "Öffentlicher Dienst, Verwaltung",
      "Landwirtschaft, Natur, Umwelt, Erneuerbare, Lebensmittel",
      "Dienstleistung, Tourismus, Gastro",
      "Naturwissenschaften",
      "Design, Kunst, Kultur, Medien",
      "Soziale Arbeit, Pädagogik, Bildung",
      "Gesundheit, Medizin und Psychologie",
    ],
    button: {
      label: "Weiter",
      action: "next",
    },
  },
  {
    question: "Gebe Stichworte ein, die dabei helfen könnten, einen passenden Job für dich zu finden (optional).",
    name: "jobKeywords",
    button: {
      label: "Weiter",
      action: "nextNoAnswerPossible",
    },
  },
  {
    question: "Nach welcher Anstellungsart würdest du suchen, wenn du jetzt einen Job suchen würdest?",
    name: "employmentType",
    options: [
      'Berufseinstieg nach dem Studium',
      'Nebenjob (fachfremd)',
      'Abschlussarbeit',
      'Praktikum',
      'Werkstudierendenstelle und HiWi-Stellen',
      'Traineeprogramm',
      'Berufserfahrene',
      'DoktorandInnen',
      'Arbeitsstelle',
      'Duales Studium',
      'MitgründerIn gesucht',
      'Ausbildung'
    ],
    button: {
      label: "Weiter",
      action: "next",
    },
  },
  {
    question: "Sprache des Lebenslaufs",
    name: "language",
    options: ["Deutsch", "Englisch"],
    button: {
      label: "Weiter",
      action: "next",
    },
  },
  {
    question:
      "Bitte kopiere den Text deines Lebenslaufs in das Textfeld und klicke auf Ergebnisse berechnen",
    name: "resumeText",
    button: {
      label: "Berechnung durchführen und zur nächsten Frage",
      action: "calculateResults",
    },
  },
  {
    question: (
      <>
        Mithilfe deiner Angaben wurden passende Stellenanzeigen mit zwei
        unterschiedlichen Methoden für dich gesucht. <br /> <br />
        <b>Methode 1 ist die aktuelle Suche der Hochschuljobbörse.</b> Dabei
        werden die gewünschte Anstellungsart und die angegebenen Stichworte
        verwendet, um nach passenden Stellenanzeigen zu suchen. <br />
        <br />
        <b>Methode 2 ist der Prototyp einer neueren Suche.</b> Hierbei wird die
        semantische Ähnlichkeit zwischen deinem Lebenslauf und den
        Stellenanzeigen berechnet, um dir die ähnlichsten Stellenangebote
        anzuzeigen. <br />
        <br />
        <b>Aufgabe:</b> <br />
        Im Folgenden werden dir die Top 10 Ergebnisse beider Methoden angezeigt.
        Bitte bewerte, wie gut diese thematisch zu dir passen. Bei Methode 2
        wird die gewünschte Anstellungsart nicht berücksichtigt – bewerte daher
        nur, ob du aufgrund deiner Fähigkeiten in der Lage wärst, diesen Job,
        ein Praktikum etc. auszuführen und ob diese theamtisch zu deinem
        Lebenslauf passen. 
      </>
    ),
    name: "explanation",
    button: {
      label: "Weiter zur Bewertung",
      action: "nextNoAnswerPossible",
    },
  },

  {
    question: (
      <>
        <b>Stellenanzeigen bewerten - Ergebnisse Methode 1</b>
      </>
    ),
    name: "jobAdsMethod1Response",
    options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    button: {
      label: "Weiter",
      action: "next",
    },
  },
  {
    question: "Warum waren die Ergebnisse der Methode 1 gut oder schlecht?",
    name: "jobAdsMethod1Reason",
    button: {
      label: "Weiter",
      action: "next",
    },
  },
  {
    question: (
      <>
        <b>Stellenanzeigen bewerten - Ergebnisse Methode 2</b>
      </>
    ),
    name: "jobAdsMethod2Response",
    options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    button: {
      label: "Weiter",
      action: "next",
    },
  },
  {
    question: "Warum waren die Ergebnisse der Methode 2 gut oder schlecht?",
    name: "jobAdsMethod2Reason",
    button: {
      label: "Weiter",
      action: "finish",
    },
  },



  // {
  //   question: (
  //     <>
  //       <b>Stellenanzeigen bewerten - Ergebnisse Methode 3</b>
  //     </>
  //   ),
  //   name: "jobAdsMethod3Response",
  //   options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  //   button: {
  //     label: "Weiter",
  //     action: "next",
  //   },
  // },
  // {
  //   question: "Warum waren die Ergebnisse der Methode 3 gut oder schlecht?",
  //   name: "jobAdsMethod3Reason",
  //   button: {
  //     label: "Umfrage abschischicken",
  //     action: "finish",
  //   },
  // },
  // {
  //   question: "Warum und warum nicht haben die Stellenanzeigen zu deinem Lebenslauf gepasst?",
  //   name: "whyFit",
  //   button: {
  //     label: "Fertig",
  //     action: "finish",
  //   },
  // },
  // Weitere Schritte hier ...
];

export default SurveySteps;
