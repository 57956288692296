import React, { useState } from "react";
import { Link } from "react-router-dom";
import system from "./bilder/system.png";

const Home = () => {
  const [agreed, setAgreed] = useState(false);

  const handleAgreementChange = () => {
    setAgreed(!agreed);
  };

  return (
    <div className="flex flex-col items-center justify-center py-3">
      <div className="bg-white shadow-md rounded-lg p-6 max-w-4xl">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-6">
          Matchingsystem für Lebensläufe und Stellenanzeigen
        </h2>
        <p className="text-lg text-gray-700 leading-8 mb-6">
          Im Rahmen meiner Masterarbeit entwickle ich ein System, das automatisch relevante Stellenanzeigen für die individuellen Bedürfnisse der Stellensuchenden findet.
        </p>

        <div className="flex justify-center mb-6">
          <img
            src={system}
            alt="System Screenshot"
            className="rounded-md w-full h-auto max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg"
          />
        </div>

        <div className="text-lg text-gray-700 leading-8 mb-6">
          <p>
            Um das optimale System zu finden, benötige ich Deine Meinung! Beantworte die Fragen, lade Deinen Lebenslauf hoch und bewerte die Ergebnisse der verschiedenen Verfahren.
          </p>
          {/* <br />
          <p>
            Unter allen Teilnehmern, die mir einen Lebenslauf zur Verfügung stellen, werden 4 mal 5€ Eisgutscheine der Nürnberger Eisdiele "Die kleine Eismanufaktur" verlost (bis zum 14.07.2024).
          </p> */}
        </div>

        <div className="mb-6">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              className="form-checkbox text-gray-900"
              checked={agreed}
              onChange={handleAgreementChange}
            />
            <span className="ml-2 text-gray-700">
              Ich erkläre mich damit einverstanden, dass meine Daten im Rahmen
              der Masterarbeit anonymisiert genutzt werden.
            </span>
          </label>
        </div>

        <div className="flex justify-center">
          <Link
            to={agreed ? "/survey" : "#"}
            className={`rounded-md px-4 py-2 text-sm font-semibold text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-opacity-50 ${
              agreed ? "bg-gray-900 hover:bg-gray-700" : "bg-gray-300 cursor-not-allowed"
            }`}
          >
            Umfrage Starten
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;