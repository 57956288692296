import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Navbar from './components/navbar.js';
import Footer from './components/footer.js';
import Survey from './Survey';
import ApiTest from './apiTest.js';
import ApiTestLokal from './apiTest_lokal.js';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  useEffect(() => {
    document.title = "Umfrage";
  }, []);

  return (
    <Router>
      <div className="bg-gray-100 min-h-screen flex flex-col">
        <Navbar />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/survey" element={<Survey />} />
            <Route path="/api-test" element={<ApiTest />} />
            <Route path="/api-test-lokal" element={<ApiTestLokal />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;