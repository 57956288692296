import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import SurveyStep from "./SurveyStep";
import SurveySteps from "./SurveySteps";

const SurveyForm = ({
  currentStep,
  responses,
  setResponses,
  handleSubmit,
  nextStep,
  prevStep,
  loading,
}) => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [jobAdsMethod1, setJobAdsMethod1] = useState([]);
  const [jobAdsMethod2, setJobAdsMethod2] = useState([]);
  const [jobAdsMethod3, setJobAdsMethod3] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Neuer Zustand für den Ladebildschirm
  const [apiResult, setApiResult] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setResponses((prevResponses) => ({
      ...prevResponses,
      [name]: value,
    }));
    setErrorMessage(""); // Clear error message on input change
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setResponses((prevResponses) => ({
      ...prevResponses,
      resumeText: file,
    }));
  };

  const isNextButtonDisabled = () => {
    const currentResponse = responses[SurveySteps[currentStep - 1]?.name];
    return !currentResponse || currentResponse.trim() === "";
  };

  const handleButtonClick = async (action) => {
    if (action === "next") {
      if (isNextButtonDisabled()) {
        setErrorMessage(
          "Bitte geben Sie eine Antwort ein, bevor Sie fortfahren."
        );
      } else {
        setErrorMessage("");
        nextStep();
      }
    } else if (action == "nextNoAnswerPossible") {
      setErrorMessage("");
      nextStep();
    } else if (action === "calculateResults") {
      if (isNextButtonDisabled()) {
        setErrorMessage(
          "Bitte geben Sie eine Antwort ein, bevor Sie fortfahren."
        );
      } else {
        setErrorMessage("");
        console.log("Ergebnisse berechnen");
        console.log("Alle Antworten:", responses);

        setIsLoading(true); // Ladebildschirm anzeigen
        // await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 Sekunden warten


        // Hier senden Sie die Antworten an den Server zum Berechnen der Ergebnisse
        try {
          const response = await fetch(
            // "http://127.0.0.1:5000/api/calculateResults",
            "http://172.104.133.31/api/calculateResults",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(responses),
            }
          );
          const result = await response.json();
          setApiResult(result);
          setJobAdsMethod1(result.stichwortsuche);
          setJobAdsMethod2(result.E5_suche);

          const e5JobIds = result.E5_suche.map((job) => job.job_id);
          const stichwortJobIds = result.stichwortsuche.map(
            (job) => job.job_id
          );

          // **Speichern der job_id's in responses**
          setResponses((prevResponses) => ({
            ...prevResponses,
            e5JobIds: e5JobIds,
            stichwortJobIds: stichwortJobIds,
          }));

          console.log("Serverantwort:", result);
        } catch (error) {
          console.error("Fehler beim Senden der Antworten:", error);
        }

        // Entfernen Sie den useEffect-Aufruf hier

        setIsLoading(false); // Ladebildschirm ausblenden
        nextStep(); // Zum nächsten Schritt gehen
      }
    } else if (action === "finish") {
      console.log("Umfrage beendet. Antworten senden:", responses);

      // Hier senden Sie die Antworten an den Server zum Speichern der Ergebnisse
      try {
        const response = await fetch("http://172.104.133.31/api/saveResults", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(responses),
        });
        const result = await response.json();
        console.log("Serverantwort:", result);
      } catch (error) {
        console.error("Fehler beim Senden der Antworten:", error);
      }

      // Nach dem Senden der Antworten auf die About-Seite weiterleiten
      navigate("/about");
    } else {
      console.warn(`Unbekannte Aktion: ${action}`);
    }
  };

  // Verschieben Sie den useEffect-Hook hierhin, außerhalb von handleButtonClick
  useEffect(() => {
    if (apiResult) {
      console.log("############");
      console.log(apiResult);
      console.log("############");

      // Weitere Aktionen mit apiResult
    }
  }, [apiResult]);

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-4xl mx-auto">
      {isLoading ? (
        <div
          className="flex flex-col items-center justify-center w-full mx-auto mt-20 bg-white rounded-lg shadow-md p-4"
          style={{ minHeight: "20vh" }}
        >
          <svg
            className="animate-spin h-12 w-12 text-blue-500 mb-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
            ></path>
          </svg>
          <p className="text-lg text-gray-700 text-center">
            Die Ergebnisse werden berechnet. Dies kann bis zu 3 Minuten dauern.
            Bitte haben Sie einen Moment Geduld. <br />
            <br />
            Es werden Stellenanzeigen mit unterschiedlichen Methoden gesucht. Du
            sollst die Ergebnisse danach von 1-10 bewerten.
          </p>
        </div>
      ) : (
        <>
          {SurveySteps[currentStep - 1] ? (
            <SurveyStep
              step={SurveySteps[currentStep - 1]}
              response={responses[SurveySteps[currentStep - 1].name]}
              handleInputChange={handleInputChange}
              handleFileChange={handleFileChange}
              jobAds={
                SurveySteps[currentStep - 1].name === "jobAdsMethod1Response"
                  ? jobAdsMethod1
                  : SurveySteps[currentStep - 1].name ===
                    "jobAdsMethod2Response"
                  ? jobAdsMethod2
                  : SurveySteps[currentStep - 1].name ===
                    "jobAdsMethod3Response"
                  ? jobAdsMethod3
                  : []
              } // Stellenanzeigen an SurveyStep weitergeben
            />
          ) : (
            <p>Ungültiger Schritt</p>
          )}
          {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
          <div className="flex justify-between mt-6">
            <button
              type="button"
              onClick={prevStep}
              className={`rounded-md px-4 py-2 text-sm font-semibold ${
                currentStep === 1 || loading
                  ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                  : "bg-gray-900 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-opacity-50"
              }`}
              disabled={currentStep === 1 || loading}
            >
              Zurück
            </button>
            {SurveySteps[currentStep - 1]?.button && (
              <button
                type="button"
                onClick={() =>
                  handleButtonClick(SurveySteps[currentStep - 1].button.action)
                }
                className="rounded-md bg-gray-900 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-opacity-50"
              >
                {SurveySteps[currentStep - 1].button.label}
              </button>
            )}
          </div>
        </>
      )}
    </form>
  );
};

export default SurveyForm;
