import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";

const SurveyStep = ({
  step,
  response,
  handleInputChange,
  handleFileChange,
  jobAds,
}) => {
  const sliderRef = useRef(null);
  const containerRef = useRef(null); // Ref für den Slider-Container
  const [currentSlide, setCurrentSlide] = useState(0); // Zustand, um den aktuellen Slide zu verfolgen

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (current) => handleSlideChange(current), // Callback nach Slide-Wechsel
  };

  // Funktion, um die Höhe des Containers anzupassen
  const handleSlideChange = (index) => {
    setCurrentSlide(index);
    adjustHeight(index);
  };

  // Funktion, die die Höhe des Sliders anpasst
  const adjustHeight = (index) => {
    if (containerRef.current) {
      const activeSlide = containerRef.current.querySelectorAll(
        ".slick-slide"
      )[index];
      if (activeSlide) {
        const newHeight = activeSlide.offsetHeight;
        containerRef.current.style.height = `${newHeight}px`; // Höhe dynamisch anpassen
      }
    }
  };

  useEffect(() => {
    // Stelle sicher, dass die Höhe beim ersten Laden angepasst wird
    adjustHeight(currentSlide);
  }, [currentSlide]);

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className="mb-6 relative w-full px-4 max-w-xs sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-4xl mx-auto">
      {step && (
        <label className="block text-lg text-gray-700 leading-8 mb-6">
          {step.question}
        </label>
      )}

      {step?.name.includes("jobAds") && jobAds.length > 0 ? (
        <>
          <div className="flex justify-center items-center max-w-4xl mx-auto">
            <button
              type="button"
              onClick={prevSlide}
              className="bg-gray-900 text-white rounded-full p-2 focus:outline-none z-10 mx-2"
            >
              &#9664;
            </button>

            {/* Slider Container mit dynamischer Höhe */}
            <div
              className="w-full flex-grow max-w-[800px] overflow-hidden"
              ref={containerRef}
            >
              <Slider ref={sliderRef} {...settings}>
                {jobAds.map((ad, index) => (
                  <div
                    key={index}
                    className="p-4 border rounded-lg shadow-md bg-white mb-4 min-h-[300px] max-w-full"
                  >
                    <h2 className="text-2xl font-bold">{ad.jobtitel}</h2>
                    <h3 className="text-xl font-bold mt-2">{ad.angebottitel}</h3>
                    <p className="text-md">{ad.kurzbeschreibung}</p>
                    <h4 className="text-lg font-bold mt-2">
                      {ad.einleitungstitel}
                    </h4>
                    <p className="text-md">{ad.einleitungstext}</p>
                    <h4 className="text-lg font-bold mt-2">{ad.aufgabentitel}</h4>
                    <p className="text-md">{ad.aufgabentext}</p>
                    <h4 className="text-lg font-bold mt-2">{ad.angebotstitel}</h4>
                    <p className="text-md">{ad.angebotstext}</p>
                  </div>
                ))}
              </Slider>
            </div>

            <button
              type="button"
              onClick={nextSlide}
              className="bg-gray-900 text-white rounded-full p-2 focus:outline-none z-10 mx-2"
            >
              &#9654;
            </button>
          </div>

          {/* Bewertungsfrage */}
          <div className="mt-4 max-w-[800px] mx-auto">
            <label className="block text-lg text-gray-700 leading-8 mb-2">
              Bitte bewerten, wie gut die Stellenanzeigen zu dir thematisch passen
              (nicht Ort, Art der Anstellung etc.)
              <br />
              <b>1</b> = die stellen passen <b>thematisch schlecht</b>
              <br />
              <b>10</b> = die stellen passen <b>thematisch sehr gut</b>
            </label>
            <select
              name={step.name}
              value={response}
              onChange={handleInputChange}
              className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-gray-900 focus:ring focus:ring-gray-900 focus:ring-opacity-50"
              required
            >
              <option value="">Bitte wählen...</option>
              {step.options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </>
      ) : step?.options ? (
        <select
          name={step.name}
          value={response}
          onChange={handleInputChange}
          className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-gray-900 focus:ring focus:ring-gray-900 focus:ring-opacity-50"
          required
        >
          <option value="">Bitte wählen...</option>
          {step.options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      ) : step.name.includes("Reason") ? (
        <textarea
          name={step.name}
          value={response}
          onChange={handleInputChange}
          rows="4"
          className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-gray-900 focus:ring focus:ring-gray-900 focus:ring-opacity-50"
          required
        ></textarea>
      ) : step.name === "resumeText" ? (
        <div>
          <textarea
            name={step.name}
            value={response}
            onChange={(e) => {
              const value = e.target.value;
              const wordCount = value.trim().split(/\s+/).length; // Zählt die Wörter

              if (wordCount <= 512) {
                handleInputChange(e); // Setzt den Wert nur, wenn die Anzahl der Wörter <= 512 ist
              }
            }}
            rows="4"
            className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-gray-900 focus:ring focus:ring-gray-900 focus:ring-opacity-50"
            placeholder="Mindestens 100 und maximal 512 Wörter"
            required
          ></textarea>

          <p className="text-sm text-gray-600 mt-2">
            Es müssen mindestens 100 Wörter eingegeben werden. <br />
            {response.trim().split(/\s+/).length} von 100 Wörtern (maximal 512
            Wörter)
          </p>
        </div>
      ) : step.name === "jobKeywords" ? (
        <textarea
          name={step.name}
          value={response}
          onChange={(e) => {
            let value = e.target.value;

            // Entferne alle nicht-Buchstaben-Zeichen außer Leerzeichen
            value = value.replace(/[^a-zA-Z\s]/g, "");

            // Verhindere mehr als ein Leerzeichen hintereinander
            value = value.replace(/\s{2,}/g, " ");

            const wordCount = value.trim().split(/\s+/).length;
            const charCount = value.length;

            // Stelle sicher, dass die Wortanzahl ≤ 3 ist und die Zeichenanzahl ≤ 30
            if (wordCount <= 3 && charCount <= 30) {
              handleInputChange({ target: { name: step.name, value } });
            }
          }}
          rows="4"
          className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-gray-900 focus:ring focus:ring-gray-900 focus:ring-opacity-50"
          placeholder="Maximal 3 Stichwörter"
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SurveyStep;