// Survey.js
// Diese Datei enthält die Hauptkomponente, die den gesamten Umfrage-Workflow verwaltet.
// Sie hält den Zustand der aktuellen Umfrageantworten und steuert die Navigation zwischen den einzelnen Schritten.
// Zudem wird das Formular über die SurveyForm-Komponente und die Umfragefragen über die SurveySteps-Daten verwaltet.

import React, { useState } from "react";
import SurveyForm from "./SurveyForm";
import SurveySteps from "./SurveySteps";

const Survey = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [responses, setResponses] = useState({
    status: "",
    experience: "",
    field: "",
    jobKeywords: "",
    language: "",
    resumeText: "",
    employmentType: "",
    jobKeywords: "",
  });
  const [loading, setLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState(null);

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      for (const key in responses) {
        formData.append(key, responses[key]);
      }

      const response = await fetch("YOUR_BACKEND_ENDPOINT", {
        method: "POST",
        body: formData,
      });

      const result = await response.json();
      setServerResponse(result);
    } catch (error) {
      console.error("Fehler bei der Übermittlung der Daten:", error);
      setServerResponse({ status: "error", message: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut." });
    } finally {
      setLoading(false);
    }

    nextStep();
  };

  return (
    <div className="flex flex-col items-center justify-center py-3">
      <div className="bg-white shadow-md rounded-lg p-6 max-w-4x">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-6">
          Umfrage: Frage {currentStep} von {SurveySteps.length}
        </h2>
        <SurveyForm
          currentStep={currentStep}
          responses={responses}
          setResponses={setResponses}
          handleSubmit={handleSubmit}
          nextStep={nextStep}
          prevStep={prevStep}
          loading={loading}
        />
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-gray-100"></div>
          </div>
        )}
      </div>
      {serverResponse && (
        <div className="mt-4 text-gray-700">
          {serverResponse.status === "success" ? (
            <p>{serverResponse.message}</p>
          ) : (
            <p>Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Survey;