import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";

const About = () => {
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
  });

  const [submissionMessage, setSubmissionMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 4000); 

    return () => clearTimeout(timer);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("http://172.104.133.31/api/saveContactDetails", { // Hier den richtigen Endpunkt setzen
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contactData),
      });

      if (response.ok) {
        setSubmissionMessage("Kontaktdaten erfolgreich übermittelt!");
        setIsSubmitted(true);
      } else {
        setSubmissionMessage("Fehler bei der Übermittlung der Kontaktdaten.");
      }
    } catch (error) {
      setSubmissionMessage("Es ist ein Fehler aufgetreten. Bitte versuche es erneut.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center py-3">
      {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}
      <div className="bg-white shadow-md rounded-lg p-6 max-w-4xl">
        <h1 className="text-4xl font-bold text-gray-900 mb-6">Danke!</h1>
        <p className="text-lg text-gray-700 leading-8 mb-6">
          Vielen Dank für Deine Teilnahme an der Umfrage! Deine Meinung ist von großer Bedeutung und hilft mir, das System zu verbessern.
        </p>

        <div className="text-lg text-gray-700 leading-8 mb-10">
          <p>
            Ich hoffe, dass Du bei der Verlosung der Eisgutscheine erfolgreich bist und wünsche Dir viel Erfolg bei der Jobsuche. Solltest Du weitere Fragen oder Anmerkungen haben, kannst Du mich jederzeit kontaktieren.
          </p>
          <br />
          <p>
            Vielen Dank nochmals für Deine Unterstützung!
          </p>
        </div>

        <div className="text-lg text-gray-700 leading-8 mb-6 w-full flex flex-col items-center">
          <h2 className="text-2xl font-bold mb-4">Kontaktdaten für das Gewinnspiel</h2>
          {!isSubmitted ? (
            <form onSubmit={handleSubmit} className="w-full max-w-lg">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={contactData.name}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  E-Mail
                </label>
                <input
                  type="email"
                  name="email"
                  value={contactData.email}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="rounded-md px-4 py-2 text-sm font-semibold text-white bg-gray-900 hover:bg-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-opacity-50"
                >
                  Kontaktdaten abgeben
                </button>
              </div>
            </form>
          ) : (
            <p className="text-center text-green-500 font-semibold">
              Kontaktdaten wurden bereits übermittelt. Vielen Dank!
            </p>
          )}
          {submissionMessage && (
            <div className="mt-4 text-center text-gray-700">
              <p>{submissionMessage}</p>
            </div>
          )}
        </div>

        <div className="flex justify-center mt-4">
          <a
            href="/"
            className="rounded-md px-4 py-2 text-sm font-semibold text-white bg-gray-900 hover:bg-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-opacity-50"
          >
            Zurück zur Startseite
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;