import React from "react";
import { Link } from "react-router-dom";
import system from "../bilder/Logo_Th_Nürnberg.png";

function Navbar() {
  return (
    <nav className="bg-gray-800">
      <div className="container mx-auto px-2 py-3">
        <div className="flex items-center justify-between">
          <div className="text-white text-lg font-bold">
            <Link
              to="/"
              className="text-white hover:text-gray-200 transition-colors duration-200"
            >
              Umfrage Masterarbeit
            </Link>
          </div>
          {/* <Link to="/api-test" className="ml-auto"> */}
            <img
              src={system}
              alt="Logo"
              className="rounded-md w-32 lg:w-40 h-auto"
            />
          {/* </Link> */}
          {/* <ul className="flex space-x-4"> 
            <li>
              <Link to="/" className="text-white hover:text-gray-200 transition-colors duration-200">Home</Link>
            </li>
            <li>
              <Link to="/about" className="text-white hover:text-gray-200 transition-colors duration-200">About</Link>
            </li>
          </ul> */}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;