import React from 'react';

const Navbar = () => {
  return (
    <nav className="bg-gray-800 p-4">
      <div className="text-white text-xs text-center mt-2">
        © 2024 Umfrage Masterarbeit.
      </div>
      <div className="text-white text-xs text-center mt-2">
        Kontakt: scharnaglch91074@th-nuernberg.de
      </div>
    </nav>
  );
}

export default Navbar;